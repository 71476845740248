import React from 'react'
import { graphql } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import BannerBlock from 'components/blocks/BannerBlock'
import NewsIntroBlock from 'components/blocks/NewsIntroBlock'
import BodyBlock from 'components/blocks/BodyBlock'
import FeaturedCollection from 'components/FeaturedCollection'
import ProductsBlock from 'components/blocks/ProductsBlock'
import FeaturedItemBlock from 'components/blocks/FeaturedItemBlock'
import SlideshowCenteredBlock from 'components/blocks/SlideshowCenteredBlock'
import SlideshowMoreItemsBlock from 'components/blocks/SlideshowMoreItemsBlock'
import { getNextItems } from 'js/utils'

export default function NewsPost({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiNewsPost
  const allCollections = data.allStrapiCollection.nodes
  const allDiamonds = data.allStrapiDiamond.nodes
  const allNewsPosts = data.allStrapiNewsPost.nodes

  if (!page) return false
  const { introBannerBlock, newsPostBuilder } = page
  const customBanner = {
    image: introBannerBlock?.image,
    textColor: introBannerBlock?.textColor,
  }

  const nextNewsPosts = getNextItems(allNewsPosts, page.slug, 4)
  const customSlideshowBlock = {
    title: tr('moreNewsPress', locale),
    slides: nextNewsPosts.map((post) => ({
      image: post.bannerImageOnlyBlock?.image,
      date: post.date,
      title: post.title,
      link: `/news/${post.slug}/`,
    })),
  }

  return (
    <div className='page-news-post'>
      <BannerBlock content={customBanner} hasIntroBlock={true} />
      <NewsIntroBlock content={page} />
      {newsPostBuilder && (
        <Post
          content={newsPostBuilder}
          allCollections={allCollections}
          allDiamonds={allDiamonds}
        />
      )}
      <SlideshowMoreItemsBlock content={customSlideshowBlock} />
    </div>
  )
}

const Post = ({ content, allCollections, allDiamonds }) => {
  const {
    introBodyBlock,
    relatedCollection,
    bodyBlock2,
    relatedProducts,
    bodyBlock3,
    slideshowBlock,
    relatedDiamond,
  } = content

  return (
    <>
      {introBodyBlock && <BodyBlock content={introBodyBlock} />}
      {relatedCollection && (
        <FeaturedCollection
          content={relatedCollection}
          allCollections={allCollections}
          isRelatedCollection={true}
          showSlideshow={false}
          mobileBgBeige={true}
        />
      )}
      {bodyBlock2 && <BodyBlock content={bodyBlock2} />}
      {/* {relatedProducts && (
        <ProductsBlock products={relatedProducts.products} isRelated={true} />
      )} */}
      {bodyBlock3 && <BodyBlock content={bodyBlock3} />}
      {slideshowBlock && (
        <SlideshowCenteredBlock content={slideshowBlock} loop={false} />
      )}
      {/* {relatedDiamond && relatedDiamond.diamond && (
        <FeaturedItemBlock
          item={relatedDiamond.diamond}
          set={allDiamonds}
          itemType='diamond'
        />
      )} */}
    </>
  )
}

export const pageQuery = graphql`
  query ($locale: String!, $slug: String!) {
    strapiNewsPage(locale: { eq: $locale }) {
      title
    }
    strapiNewsPost(slug: { eq: $slug }, locale: { eq: $locale }) {
      slug
      date
      title
      excerpt
      seo {
        title
        description
      }
      introBannerBlock: bannerImageOnlyBlock {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        textColor
      }
      newsPostBuilder {
        introBodyBlock {
          body
        }
        relatedCollection {
          featuredCollection {
            slug
          }
        }
        bodyBlock2 {
          title
          body
        }
        bodyBlock3 {
          title
          body
        }
        slideshowBlock {
          title
          images {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiCollection(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        slug
        bannerBlock {
          pretitle
          title
          excerpt
          link
          alignH
          alignV
          textColor
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          link
        }
      }
    }
    allStrapiDiamond(filter: { locale: { eq: $locale } }) {
      nodes {
        strapiId
        slug
        title
        excerpt
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiNewsPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        slug
        date
        title
        bannerImageOnlyBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

// relatedDiamond {
//   diamond {
//     id
//   }
// }

// relatedProducts {
//   products {
//     title
//     excerpt
//     image {
//       localFile {
//         childImageSharp {
//           gatsbyImageData
//         }
//       }
//     }
//   }
// }
